
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { IonItem, IonLabel, IonInput, IonButton, IonToast, IonSpinner } from "@ionic/vue";
import axios from "axios";
import { openToastError, openToastSuccess } from "@/assets/ts/toast";
import storageController from "@/assets/ts/storageController";
  
  interface ResetPass {
    email: string;
  }
  export default defineComponent({
    name: "ResetPassword",
    components: {
      IonItem,
      IonLabel,
      IonInput,
      IonButton,
    },
    setup() {
      const store = new storageController();
      const loading = ref(false);
      const data = ref({
        email: "",
      } as ResetPass);
      const router = useRouter();
      const goToSignUp = () => {
        router.push("/signup");
      };
        const goToLogIn = () => {
            router.push("/auth");
      };
      const resetPassword = async () => {
        loading.value = true;
        // check if data is filled
        for (const value of Object.values(data.value)) {
          if (value == "") {
            openToastError({ message: "Compila tutti i campi" });
            loading.value = false;
            return;
          }
        }
    
        const backLink = await store.get("backLink");
        axios
          .post(`${backLink}/user/reset_password_request`, {
            email: data.value.email,
          }, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(async (res) => {
            if (res.status == 200) {
               openToastSuccess({ message: "Email di reset inviata con successo" });
            }
            loading.value = false;
          })
          .catch((err) => {
        openToastError({message:"Errore"});
        loading.value = false;
    });
      };
      return {
        data,
        resetPassword,
        goToSignUp,
        goToLogIn,
      };
    },
  });
