
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { IonItem, IonLabel, IonInput, IonButton, IonToast, IonSpinner} from "@ionic/vue";
import axios from "axios";
import { openToastError } from "@/assets/ts/toast";
import storageController from "@/assets/ts/storageController";
import axiosWrapper from "@/assets/ts/axiosWrapper";
import emitter from "@/assets/ts/emitter";
  
  interface LogIn {
    username: string;
    password: string;
  }
  export default defineComponent({
    name: "Login",
    components: {
      IonItem,
      IonLabel,
      IonInput,
      IonButton,
    },
    setup() {
      const router = useRouter();
      const store = new storageController();
      const loading = ref(false);
      const goToSignUp = () => {
        router.push("/signup");
      };
      const goToResetPass = () => {
        router.push("/reset-password");
      };
      const data = ref({
        username: "",
        password: "",
      } as LogIn);
      const logIn = async () => {
        loading.value = true;
        // check if data is filled
        for (const value of Object.values(data.value)) {
          if (value == "") {
            openToastError({ message: "Compila tutti i campi" });
            loading.value = false;
            return;
          }
        }
        // make login
        const backLink = await store.get("backLink");
        axios
          .post(`${backLink}/jwt/login`, {
            username: data.value.username,
            password: data.value.password,
          }, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            }
          })
          .then(async (res) => {
            if (res.status == 200) {
              await store.set("token", res.data.access_token);
              await store.set("refreshToken", res.data.refresh_token);
              axiosWrapper
                .get(`${backLink}/user/me`)
                .then(async(res) => {
                  await store.set("perm", res.data.perm);
                  await store.set("userData", res.data);
                  loading.value = false;
                  emitter.emit("loginSuccess");
                  router.push("/home");
                })
                .catch((err) => {
                  openToastError({message:"Credenziali errate"});
                  loading.value = false;
                });
            }
          })
          .catch((err) => {
            openToastError({message:"Credenziali errate"});
            loading.value = false;
          });
      };
      return {
        goToSignUp,
        goToResetPass,
        data,
        logIn,
        loading,
      };
    },
  });
