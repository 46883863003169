import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-354064f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rightHeight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResetPassword = _resolveComponent("ResetPassword")!
  const _component_IonContent = _resolveComponent("IonContent")!
  const _component_IonPage = _resolveComponent("IonPage")!

  return (_openBlock(), _createBlock(_component_IonPage, null, {
    default: _withCtx(() => [
      _createVNode(_component_IonContent, {
        id: "container",
        fullscreen: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ResetPassword)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}