import { toastController } from "@ionic/vue";

export interface ToastOptions {
  message: string;
  duration?: number;
  position?: "top" | "bottom" | "middle";
  color?: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark";
}

export async function openToastSuccess(options: ToastOptions) {
  if(! ('duration' in options)) options.duration = 2000;
  if(! ('position' in options)) options.position = "bottom";
  options.color = "success";
  const toast = await toastController
    .create(options);
  return toast.present();
}

export async function openToastError(options: ToastOptions) {
  if(! ('duration' in options)) options.duration = 2000;
  if(! ('position' in options)) options.position = "bottom";
  options.color = "danger";
  const toast = await toastController
    .create(options);
  return toast.present();
}