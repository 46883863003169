/**
 * 
 * * emitta evento "goToLogin" se refresh fallisce
 * 
 */


import axios from 'axios';
// requires storageController
import StorageController from './storageController';
import emitter from './emitter';

const refreshTokenRoute = "/jwt/refreshAccessToken";


const store = new StorageController();
const axiosWrapper = axios.create();

axiosWrapper.interceptors.request.use(
  async (config) => {
    const token = await store.get("token");
    if (token) {
      if(config.headers != undefined)
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);  

axiosWrapper.interceptors.response.use(
  async response => {
    return response},
  async function (error) {
    const originalRequest = error.config;
    const backLink = await store.get("backLink");

    // se anche il rt è scaduto, rimanda al login
    if ((error.response.status === 403 || error.response.status === 401) && originalRequest.url === `${backLink}${refreshTokenRoute}`) {
      store.remove("token");
      store.remove("refreshToken"); 
      emitter.emit("goToLogin")
      return Promise.reject(error);
    }
    
    // se il rt non è scaduto ma at sì, refresha at
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      await store.remove("token");
      const refreshToken = await store.get("refreshToken");
      return axios.post(`${backLink}${refreshTokenRoute}`, {}, 
      {
        headers:{
          "Authorization": `Bearer ${refreshToken}`
        }
      }).then(async (res) => {
        if(res.status === 200) {
          await store.set("token", res.data.access_token);
          axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
          return axiosWrapper(originalRequest);
        } 
      })
    }
    return Promise.reject(error);
  }
);


export default axiosWrapper;