
import { tsUndefinedKeyword } from '@babel/types';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonLabel } from '@ionic/vue'

import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
  name: 'Card',
  props: {
    id: {
      type: String,
      required: true,
    },
    customer: {
      type: String,
      required: true,
    },
    da: {
      type: String,
      required: true,
    },
    a: {
      type: String,
      required: true,
    },
    numeroPacchi: {
      type: Number,
      required: true,
    },
    stato: {
      type: [Number],
    },
    opUsername: {
      type: String,
    },
    date: {
      type: String,
    },
    timestamp: {
      type: String,
    },
  },
  components: {
    IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonLabel
  },
  setup(props) {
    const statoOrdine = computed(() => {
      switch (props.stato) {
        case 1:
          return 'Accettato';
        case 0:
          return 'In sospeso';
        case -1:
          return 'Rifiutato';
        case 2:
          return 'Completato';
        default:
          return 'In attesa di assegnazione';
      }
    });

    const colorSwitch = computed(() => {
      switch (props.stato) {
        case 1:
          return 'success';
        case 0:
          return 'warning';
        case -1:
          return 'danger';
        case 2:
          return 'dark';
        default:
          return 'primary';
      }
    });

    const dataRitiro = computed(() => {
      if (props.date != undefined) {
        return props.date.slice(0, 10).split('-').reverse().join('/');
      }
      else {
        return 'Non disponibile';
      }
    });

    const dataRichiesta = computed(() => {
      if (props.timestamp != undefined) {
        return props.timestamp.slice(0, 10).split('-').reverse().join('/');
      }
      else {
        return 'Non disponibile';
      }
    });

    const oraRichiesta = computed(() => {
      if (props.timestamp != undefined) {
        return props.timestamp.slice(11, 16);
      }
      else {
        return 'Non disponibile';
      }
    });
    return {
      statoOrdine,
      colorSwitch,
      dataRitiro,
      dataRichiesta,
      oraRichiesta
    };
  },
});
