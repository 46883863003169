<template>
    <IonPage>
      <IonContent class="bgIonColor1" :fullscreen="true">
        <div class="rightHeight" id="signupContainer"><SignUp /></div>
      </IonContent>
    </IonPage>
  </template>
  
  
  <script>
  import { defineComponent } from "vue";
  import { IonPage, IonContent } from "@ionic/vue";
  import { SignUp } from "@/components";
  export default defineComponent({
    name: "SignUpPage",
    components: {
      IonPage,
      IonContent,
      SignUp,
    },
  });
  </script>

  <style scoped>
  
  @media screen and (max-width: 768px) {
    .rightHeight {
    margin-top:15vh;
  }
  }

  </style>

  <style>
    .ios #signupContainer {
      padding-top: 10vh;
    }
</style>