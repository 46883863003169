import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import UserHome from '../views/UserHome.vue';
import Auth from '../views/Auth.vue';
import SignUpPage from '../views/SignUpPage.vue';
import ResetPassword from '../views/ResetPass.vue';
import StorageController from '@/assets/ts/storageController';
import AdminHome from "@/views/AdminHome.vue";
import OperatorHome from "@/views/OperatorHome.vue";
import AddTransportPage from "@/views/AddTransportPage.vue";
import UserRequestListPage from "@/views/UserRequestListPage.vue";
import InfoRequestPage from "@/views/InfoRequestPage.vue";
import PkgsPage from "@/views/PkgsPage.vue";
import PickupsPage from "@/views/PickupsPage.vue";
import { menuController } from '@ionic/vue';
import Profile from "@/views/Profile.vue";
import Notifications from "@/views/Notifications.vue";
import AddressPkgsPage from "@/views/AddressPkgsPage.vue";
import { openToastError } from '@/assets/ts/toast';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: UserHome
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/userhome',
    name: 'UserHome',
    component: UserHome
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUpPage
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/adminhome',
    name: 'AdminHome',
    component: AdminHome
  },
  {
    path: '/operatorhome',
    name: 'OperatorHome',
    component: OperatorHome
  },
  {
    path: '/packages-page',
    name: 'PkgsPage',
    component: PkgsPage
  },
  {
    path: '/pickups-page',
    name: 'PickupsPage',
    component: PickupsPage
  },
  {
    path: '/addRequest',
    name: 'AddRequest',
    component: AddTransportPage
  },
  {
    path:"/userRequests",
    name:"UserRequests",
    component: UserRequestListPage
  },
  {
    path: "/pkgInfo/:id",
    name: "PkgInfo",
    component: InfoRequestPage
  },
  {
    path: "/addresspkgs/:addr",
    name: "AddressPkgs",
    component: AddressPkgsPage
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

async function redirectHome() {
  const store = new StorageController();
  const perm = await store.get('perm');
  
  if (perm == 4 || perm == 8)
    return { name: 'AdminHome' };
  else if (perm == 2)
    return {name: "UserHome"};
  else if (perm == 1)
    return {name: "OperatorHome"};
  else if (perm == 0){
    await store.remove('token');
    await store.remove("refreshToken");
    openToastError({message: "Il tuo account non è abilitato all'accesso"});
    return {name: "Auth"};
  }
  else
    return {name: "Auth"};
}

router.beforeEach(async (to, from) => {
  const store = new StorageController();

  const token = await store.get('token');
  if (token == false && to.name != 'Auth' && to.name != 'SignUp' && to.name != 'ResetPassword') {
    console.log("red to auth")
    return { name: 'Auth' };
  }
  else if (token != false && (to.name == 'Auth' || to.name == 'SignUp')) {
    return redirectHome();
  }
  if(to.path == "/home")
    return redirectHome();

  return true;
})

export default router
