
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { defineComponent, onMounted, ref } from "vue";
import { Capacitor } from '@capacitor/core';
import StorageController from "@/assets/ts/storageController";
import emitter from "@/assets/ts/emitter";
import { useRouter } from "vue-router";
import {Tabs, Navbar} from "@/components";
import {messaging} from "@/assets/ts/firebase";
import {getToken, onMessage} from "firebase/messaging";
import { openToastError, openToastSuccess } from './assets/ts/toast';
import { v4 as uuidv4 } from 'uuid';
import axiosWrapper from './assets/ts/axiosWrapper';

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    Tabs,
    Navbar
  },
  setup() {
    const isMobile = ref(false);
    const store = new StorageController();
    const router = useRouter();
    emitter.on("goToLogin", () => {
      router.push("/auth");
    });
    const getRuolo = async () => {
      const backLink = await store.get("backLink");
      const userId = await store.get("id");
    };
    
    emitter.on("app-getUserRuolo", () => getRuolo());
    onMounted(async () => {
      // set link backend http://212.227.6.1:8081
      // set link backend https://galapp-back.cs.keliweb.cloud
      // await store.set("backLink", "http://127.0.0.1:8000");
      await store.set("backLink", "https://galano.back.shardslab.com");
      const backLink = await store.get("backLink");
      onResize()
      window.addEventListener('resize', onResize, { passive: true })
    });
    const onResize = () => {
      if (window.innerWidth <= 760) {
          isMobile.value = true
      } else {
          isMobile.value = false
      }
    }

    // notifiche

    async function handleDeviceUUID(fcmToken: string){
      let devUUID = await store.get("devUUID");
      if(!devUUID){
        devUUID = uuidv4();
        await store.set("devUUID", devUUID);
      }
      const backLink = await store.get("backLink");
      axiosWrapper.post(`${backLink}/user/storeFCMToken`, {
        fcmToken: fcmToken,
        uuid: devUUID
      })
    }

    async function showNotificationToast(notification:any) {
      openToastSuccess({
        message: `${notification.title} - ${notification.body}`
      })
    }

    async function setupWebNotifications() {
      try {
        // register web notifications
        const permission = await Notification.requestPermission();
        if(permission !== 'granted') {
          console.log('Notification: Permission not granted');
          return;
        }  
        onMessage(messaging, (payload) => {
          console.log("FCM message received:", payload);
          // Gestisci la notifica qui
          if (payload.notification)
            showNotificationToast(payload.notification);
          emitter.emit("newNotification")
        });

        const fcmToken = await getToken(messaging);
        await store.set("fcmToken", fcmToken);
        handleDeviceUUID(fcmToken);

      } catch (error) {
        console.error(error);
      }
    }

    async function setupMobileNotifications() {
      try{
        // register mobile app notifications

        // triggered when ".register()" is called
        PushNotifications.addListener('registration',
          (token: Token) => {
            console.log('Push registration success, token: ' + token.value);           
            handleDeviceUUID(token.value);
          }  
        )

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
          (error: any) => {
            console.log('Notifiche mobile - Errore registrazione: ' + JSON.stringify(error));
          }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
          (notification: PushNotificationSchema) => {
            showNotificationToast(notification);
            emitter.emit("newNotification")
          }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
          (notification: ActionPerformed) => {
            console.log("clicked on notification", notification)
            router.push("/notifications")
          }
        );

        PushNotifications.requestPermissions().then(result => {
          if (result.receive === 'granted') {
            PushNotifications.register();
          } else {
            openToastError({message: 'Notifiche non attivate, puoi attivarle dalle impostazioni del tuo dispositivo'})
          }
        })
      } catch (error) {
        console.error(error);
      }
    }


    async function setupNotifications() {
      if(Capacitor.getPlatform() === 'web'){
        setupWebNotifications();
      } else {
        setupMobileNotifications();
      }
    }

    try {
      setupNotifications();
      emitter.on("loginSuccess", setupNotifications);
    } catch (error) {
      console.error(error);
    }

    return {
      isMobile
    };
  },
});
