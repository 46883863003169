interface ValidationResult {
    valid: boolean;
    message: string;
  }
  
  export function validateEmail(mail: string): ValidationResult {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = mail.length != 0 && re.test(String(mail).toLowerCase());
    return {
      valid,
      message: valid ? '' : 'Email non valida'
    };
  }
  
  export function validatePassword(password: string, confirmPassword: string): ValidationResult {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/;
    let msg = "";
    if (password !== confirmPassword) 
        return {message: 'Le password non coincidono',
                valid: false};
    const valid = password === confirmPassword  && password.length >= 6 && password.length <= 20 && re.test(password);
    if(!valid) msg = 'La password deve essere di almeno 6 caratteri, contenere una lettera maiuscola, una minuscola ed un numero';
    return {
      valid: valid,
      message: msg
    };
  }