
import { defineComponent } from "vue";
import { IonPage, IonContent, onIonViewWillEnter } from "@ionic/vue";
import { FieldTrasporto } from "@/components";
import StorageController from "@/assets/ts/storageController";
import { ref } from "vue";
import axiosWrapper from "@/assets/ts/axiosWrapper";
import { openToastError } from "@/assets/ts/toast";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    FieldTrasporto,
  },
  setup() {
    const permRule = ref("0");
    const allUsers = ref([]);
    const store = new StorageController();
    const getUsers = async () =>{
    if (permRule.value == '8' || permRule.value == '4') {
        const backLink = await store.get("backLink");
        axiosWrapper
        .get(`${backLink}/user/getUsers`)
        .then(res => {
            if (res.status == 200) {
                allUsers.value = res.data.filter( (user:any) => user.perm == 2);
            }
        })
        .catch((err) => {
            openToastError({message:err.response.data.detail});
        });
    }
}
    onIonViewWillEnter(async () => {
      permRule.value = await store.get("perm");
      getUsers();
    });
    

    return {
      permRule,
      allUsers,
    };
  }
});
