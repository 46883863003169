import { Preferences } from '@capacitor/preferences';

export default class StorageController {
  private async getKeys(): Promise<any> {
    return await Preferences.keys();
  }

  public async includesKey(key: string): Promise<boolean> {
    const keys = await this.getKeys();
    return keys.keys.includes(key);
  }

  public async get(key: string, parse = false): Promise<any> {
    if(!(await this.includesKey(key))) 
      return false;
    
    let { value } = await Preferences.get({ key });
    if(parse && value != null && value != undefined && value != 'undefined') 
      value = JSON.parse(value);
    return value;
  }

  public async set(key: string, value: any): Promise<any> {
    if(!(typeof value === 'string'))
      value = JSON.stringify(value);
    return await Preferences.set({ key, value });
  }

  public async clear(): Promise<any> {
    return await Preferences.clear();
  }

  public async remove(key: string): Promise<any> {
    if(!(await this.includesKey(key))) 
      return false;
    return await Preferences.remove({ key });
  } 
}