import {initializeApp} from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCdkCpT24OFGTsZeQueXbvvcrjmfbMIN30",
  authDomain: "galapp-a8cbd.firebaseapp.com",
  projectId: "galapp-a8cbd",
  storageBucket: "galapp-a8cbd.appspot.com",
  messagingSenderId: "475257012286",
  appId: "1:475257012286:web:628e55265257cb8f43516f"
};

let messaging: any;

try{

  const app = initializeApp(firebaseConfig);
  
  messaging = getMessaging(app);
  
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/firebase-messaging-sw.js").then(() => {
      console.log("Service worker registered.");
    });
  }
  
  
} catch (error) {
  console.log(error);
}

export { messaging };