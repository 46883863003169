
  import { defineComponent } from "vue";
  import { IonPage, IonContent } from "@ionic/vue";
  import { ResetPassword } from "@/components";
  export default defineComponent({
    components: {
      IonPage,
      IonContent,
      ResetPassword,
    },
  });
  